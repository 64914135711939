import * as React from "react"

import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"

import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import Paper from "@mui/material/Paper"
import { Helmet } from "react-helmet"
import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import FullPageContentContainer from "../../components/FullPageContentContainer"
import { useAppSelector } from "../../redux"

const TermsOfUse: React.FC = () => {
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  return (
    <PageContainer
      darkModeEnabled={darkModeEnabled}
      sx={{ minHeight: "100vh" }}
    >
      <AppBar title="Podcast Soundboard" />

      <Helmet title="Podcast Soundboard Terms of Use" />

      <Toolbar />

      <FullPageContentContainer>
        <Paper
          sx={{
            p: 4,
            my: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            component="h1"
            variant="h2"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Terms of Use
          </Typography>

          <Typography variant="body1" color="text.primary">
            By accessing this website for any purpose you accept the following
            Terms of Use.
            <br />
            <br />
            <b>Refunds</b>
            <br />
            <br />
            We have a generous refund policy. If you regret your purchase in any
            way and do not wish to use the app, we will gladly refund you. For a
            payment to be refunded it must be requested within two months of
            purchase. Subscription users will only be refunded their last
            month's charge.
            <br />
            <br />
            Violations of the terms of use include:
            <ul>
              <li>
                Any illegal activities, including uploading illegal material.
              </li>
              <li>Cloning, copying, or replicating intellectual property.</li>
              <li>Manipulating elements of the application for any purpose.</li>
              <li>Stealing or scraping data.</li>
              <li>Sending malicious files, code, or commands.</li>
              <li>Harming the application's performance or security.</li>
            </ul>
            Website maintenance or downtime that could affect availability of
            the application can happen at any time.
            <br />
            <br />
            <b>Liability</b>
            <br />
            <br />
            This application offers no warranties and we do not provide an
            uptime guarantee or service level agreement. We are not liable for
            any costs incurred that result from your use of this application. Do
            not build software integrations or automation on top of this
            application without explicit approval from site administrators.
            <br />
            <br />
            <b>Services</b>
            <br />
            <br />
            Services offered by this application are liable to change.
            Functionality, including paid features, may be modified, added or
            removed at any time.
            <br />
            <br />
            <b>Bug bounty</b>
            <br />
            <br />
            We appreciate the work of security researchers but we do not have a
            bug bounty program and we make no guarantees about compensation.
            Responsible disclosure or inquiries can be performed via the
            website's contact form. We will never prosecute ethical penetration
            testing, so long as it does not interfere with other people.
            <br />
            <br />
            <b>Violations</b>
            <br />
            <br />
            Violation of the Terms of Use can result in your account being
            terminated or your access being blocked. You are not entitled to a
            refund of purchases made on the website if your account is
            terminated for violating the Terms of Use.
            <br />
            <br />
            <b>License keys and accounts</b>
            <br />
            <br />
            Accounts should not be shared between multiple individuals (only one
            person should know the password to an account). App license keys are
            intended to be used with one device at a time. If you are an
            individual with multiple devices, reach out and we can issue you
            additional license keys.
            <br />
            <br />
          </Typography>
        </Paper>
      </FullPageContentContainer>

      <Footer />
    </PageContainer>
  )
}

export default TermsOfUse
